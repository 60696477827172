import { Store } from '../redux/Store'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify'
import memoizee from 'memoizee'
import moment from 'moment'
import qs from 'qs'
import { servicesUnavailable } from 'app/views/routes'

export class BaseHttpHandler {
  static _shared = null

  _httpClient = axios

  constructor(httpClient) {
    this._httpClient = httpClient
    if (!this._httpClient.interceptors.response.handlers.length)
      this._httpClient.interceptors.response.use(
        (response) => response,
        (error) => {
          this.clearMemos()
          if (error.message === 'Network Error') {
            if (window.navigator.onLine) {
              if (window.location.pathname !== servicesUnavailable.path) {
                window.location.pathname = servicesUnavailable.path
              }
            } else {
              // Reloading the page will cause the app to re-render and show the offline page
              window.location.reload()
            }
            return Promise.reject(error)
          } else if (error.response && error.response.status === 401) {
            localStorage.clear()
            window.location.href = '/'
          } else {
            if (error.response && error.response.data) {
              const { message } = error.response.data

              if (message) toast.error(message, { autoClose: 10000 })
            }
            return Promise.reject(error)
          }
        }
      )

    if (!this._httpClient)
      throw new Error(
        'Api client cannot be in instantiated without a httpClient.'
      )

    // if (process.env.NODE_ENV !== 'development')
    this._get = memoizee(this._rawGet, {
      normalizer: JSON.stringify,
      max: 20,
      maxAge: 30 * 60 * 1000,
    })
  }

  /**
   * Returns a shared api instance
   *
   * @returns {BaseHttpHandler} Api shared instance
   */
  static get shared() {
    if (this._shared === null) this._shared = new this(axios)
    return this._shared
  }

  get _defaultHeaders() {
    const { auth } = Store.getState()

    const headers = {}
    if (auth) headers['authorization'] = `Token ${auth.token}`

    return headers
  }

  clearMemos() {
    try {
      this._get.clear()
    } catch (error) {
      console.warn('Unable to clear memos')
    }
  }

  /**
   * Handle a http request
   *
   * @param {string} method Http request method
   * @param {AxiosRequestConfig} config Axios config object
   */
  _request(method = 'GET', config = {}) {
    if (!window.navigator.onLine) {
      return Promise.reject(new Error('Unable to make call when offline'))
    }
    if (method !== 'GET') this.clearMemos()
    return this._httpClient.request({
      timeout: 10000,
      ...config,
      method,
      baseURL: process.env.REACT_APP_BACKEND_URL,
      headers: {
        ...this._defaultHeaders,
        ...(config?.headers || {}),
      },
    })
  }

  /**
   * Handle an http GET request
   *
   * @param {string} url Request url
   * @param {AxiosRequestConfig} config Axios config object
   */
  _rawGet = (url, config = {}) => {
    return this._request('GET', {
      url,
      ...config,
    })
  }
  _get = this._rawGet

  /**
   * Handle an http POST request
   *
   * @param {string} url Request url
   * @param {object} data Request data
   * @param {AxiosRequestConfig} config Axios config object
   */
  _post(url, data = {}, config = {}) {
    return this._request('POST', {
      url,
      data,
      ...config,
    })
  }

  /**
   * Handle an http PUT request
   *
   * @param {string} url Request url
   * @param {object} data Request data
   * @param {AxiosRequestConfig} config Axios config object
   */
  _put(url, data = {}, config = {}) {
    return this._request('PUT', {
      url,
      data,
      ...config,
    })
  }

  /**
   * Handle an http DELETE request
   *
   * @param {string} url Request url
   * @param {AxiosRequestConfig} config Axios config object
   */
  _delete(url, config = {}) {
    return this._request('DELETE', {
      url,
      ...config,
    })
  }

  async _fileDownload(
    url,
    fileName = null,
    method = 'GET',
    config = {},
    useFileSystemHandler = false
  ) {
    const supportsFileSystemAccess =
      'showSaveFilePicker' in window &&
      (() => {
        try {
          return window.self === window.top
        } catch {
          return false
        }
      })()
    let fileSystemHandler
    if (supportsFileSystemAccess && useFileSystemHandler) {
      try {
        // Show the file save dialog.
        fileSystemHandler = await window.showSaveFilePicker({
          suggestedName: fileName,
        })
      } catch (err) {
        console.warn(err.name, err.message)
      }
    }
    return this._request(method, {
      url,
      timeout: 5 * 60 * 1000,
      responseType: 'blob',
      ...config,
    })
      .then(
        ({ data, headers }) => [data, headers],
        (error) => {
          if (error.response) {
            const fr = new FileReader()

            fr.addEventListener('load', (e) => {
              try {
                const data = JSON.parse(fr.result)
                toast.error(data.message)
              } catch (error) {
                toast.error(fr.result)
              }
            })

            fr.readAsText(error.response.data)
          }
          throw error
        }
      )
      .then(async ([fileData, headers]) => {
        if (!fileName) {
          try {
            const contentDisposition = headers['content-disposition']
            const [_, ...args] = contentDisposition // eslint-disable-line
              .split(';')
              .map((v) => v.trim())
            const parsedArgs = Object.fromEntries(args.map((a) => a.split('=')))
            fileName = parsedArgs['filename']
          } catch (error) {
            console.warn(
              'Unable to parse filename from Content-Distribution',
              error
            )
          }
        }

        // If the File System Access API is supported…
        if (fileSystemHandler) {
          try {
            // Write the blob to the file.
            const writable = await fileSystemHandler.createWritable()
            await writable.write(fileData)
            await writable.close()
            return
          } catch (err) {
            console.error(err.name, err.message)
          }
        }

        const a = document.createElement('a')

        const url = URL.createObjectURL(fileData)
        a.href = url
        a.download = fileName
        a.click()
        return url
      })
  }

  list = async (app, model, filters, hydrate = []) => {
    if (!app || !model) throw Error('Unable to find app or model')
    return this._get(`${app}/api/list/${model}`, {
      params: {
        format: 'json',
        hydrate,
        ...Object.fromEntries(
          Object.entries(filters || {})
            .filter(
              ([key, value]) =>
                value !== null && value !== undefined && value !== ''
            )
            .map(([key, value]) => {
              if (typeof value == 'boolean') {
                // Convert booleans to the python way
                value = value ? 'True' : 'False'
              }
              return [key, value]
            })
        ),
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    })
  }

  reportList = async (app, reportName, filters) => {
    if (!app || !reportName) throw Error('Unable to find app or report name')
    return this._get(`${app}/api/report/${reportName}`, {
      params: filters,
    })
  }

  create = (app, model, data) => {
    return this._post(`${app}/api/form/${model}`, data, {
      timeout: 2 * 60 * 1000,
    })
  }

  update = (app, model, data, id) => {
    return this._put(`${app}/api/form/${model}/${id}`, data, {
      timeout: 2 * 60 * 1000,
    })
  }

  delete = (app, model, id) => {
    return this._delete(`${app}/api/form/${model}/${id}`)
  }

  form = (app, model, id = '') => {
    return this._get(`${app}/api/form/${model}${id ? `/${id}` : ''}`)
  }

  chart = (app, model, params = {}) => {
    return this._get(`${app}/api/chart/${model}`, {
      params,
      timeout: 50 * 60 * 1000,
    }).then((data) => data?.data)
  }

  kpi = (app, model, viewMode, params = {}) => {
    return this._get(`${app}/api/kpi/${viewMode}/${model}`, { params }).then(
      (data) => data?.data
    )
  }

  get = async (app, model, id, hydrate = []) => {
    const config = {
      params: {
        hydrate,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }
    if (['activities'].includes(app))
      return await this._get(`${app}/api/retrieve/${model}/${id}`, config).then(
        (data) => data?.data
      )
    return await this._get(
      `${app}/api/list/${model}?id=${id}&paginate_by=1`,
      config
    ).then((data) => data?.data?.data?.[0])
  }

  savePushToken = (token) => {
    return this._post(`users/api/push-notification-token`, { token })
  }

  qrCodeActivation = (user_id) => {
    return this._post(`users/api/qr-code-activation`, { user_id })
  }

  authenticate = (username, password, totpCode) => {
    return this._post(`authentication/`, {
      username,
      password,
      code: totpCode,
    })
  }

  changePassword = (new_password, current_password = undefined) => {
    return this._post('authentication/change-user-password', {
      current_password,
      new_password,
    })
  }

  _parseUserData = (personalDataType, value) => {
    if (['phone', 'cpf', 'cnpj'].includes(personalDataType))
      return value.replace(/[^\d]/g, '')
    return value
  }

  getUserContacts = (personalDataType, value) => {
    return this._post('users/api/user-contacts', {
      [personalDataType]: this._parseUserData(personalDataType, value),
    })
  }

  sendActivationOTP = (personalDataType, personalData, contact) => {
    return this._get('users/api/user-account-activation-otp', {
      timeout: 60000,
      params: {
        [personalDataType]: this._parseUserData(personalDataType, personalData),
        contact,
      },
    })
  }

  verifyActivationOTP = (personalDataType, personalData, otp) => {
    return this._post('users/api/user-account-activation-otp', {
      [personalDataType]: personalData.replace(/[^\d]/g, ''),
      otp,
    })
  }

  getUserInfo = (token) => {
    return this._get(`users/api/user-info`, {
      headers: {
        authorization: `Token ${token}`,
      },
    })
  }

  persistSession = (token, next, newTab = false) => {
    const path = `${
      process.env.REACT_APP_BACKEND_URL
    }/authentication/login?${new URLSearchParams({
      token,
      next: next.replace(/&/g, '%26'),
    })}`

    if (newTab) window.open(path)
    else window.location.href = path
  }

  getMediaUrl = (uri) => {
    if (uri?.startsWith('http')) return uri
    return `${process.env.REACT_APP_BACKEND_URL}/media/${uri}`
  }

  getSignedMediaUrl = (url) => {
    return this._get(`utilities/api/sign-media-url?media_path=${url}`)
  }

  getPrintPath = (app, model, id) => {
    return `/${app}/api/print/${model}/${id}`
  }

  getPermission = (key) => {
    return this._rawGet(`users/api/user-data-permission/${key}`)
  }

  setPermission = (key, value) => {
    return this._post(`users/api/user-data-permission/${key}`, { value })
  }

  getPhysicalDeviceToken = () => {
    return this._rawGet(`physical-devices/api/physical-device-token/`)
  }

  getPhysicalSpaceReservationInviteToken = (id) => {
    return this._rawGet(
      `physical-spaces/api/qr/physical-space-reservation-invite/${id}`
    )
  }

  getFillContractUrl = (id) => {
    return `${process.env.REACT_APP_BACKEND_URL}/contracts/api/fill/${id}`
  }

  createTOTP = () => {
    return this._post('authentication/totp/create')
  }

  verifyTOTP = (code) => {
    return this._post('authentication/totp/verify', { code })
  }

  checkTOTPRequirement = (username) => {
    return this._post('authentication/totp/check-user-totp-configuration', {
      username,
    })
  }

  financialMovementOperation = (
    operation,
    movementsPerDocument,
    paymentMethodId
  ) => {
    return this._post(
      `finances/api/financial-movement/${operation}`,
      {
        movements: movementsPerDocument,
        payment_method_id: paymentMethodId,
      },
      { timeout: 30000 }
    )
  }

  generateHealthInsuranceComparisonReport = (
    cycle,
    referenceFile,
    providerIds
  ) => {
    return this._post(
      `health-insurance/api/health-insurance-comparison-report`,
      {
        cycle,
        health_insurance_reference_file: referenceFile,
        health_insurance_provider_ids: providerIds,
      },
      { timeout: 30000 }
    )
  }

  bookActivityClass = (activityClassId, classDate) => {
    return this._post(
      `activities/api/activity-class/${activityClassId}/next-classes`,
      {
        class_date: moment(classDate).format('YYYY-MM-DD'),
      }
    )
  }

  unbookActivityClass = (activityClassId, classDate) => {
    return this._delete(
      `activities/api/activity-class/${activityClassId}/next-classes?class_date=${moment(
        classDate
      ).format('YYYY-MM-DD')}`
    )
  }

  getCustomReportFields = (app, model) => {
    return this._get(`${app}/api/custom-report/${model}`)
  }

  getCustomReportFieldForm = (app, model, field, operator) => {
    return this._get(`${app}/api/custom-report/${model}/${field}/${operator}`)
  }

  submitCustomReportForm = (app, model, formData, format = '') => {
    var suffix = format ? `/${format}` : ''
    return this._fileDownload(
      `/${app}/custom-report/${model}${suffix}`,
      `${model}-report-${moment().format('DD-MM-YYYY_HH-mm')}`,
      'POST',
      { data: formData }
    )
  }

  downloadReport = (app, reportName, params) => {
    return this._fileDownload(
      `/${app}/api/report/${reportName}?${new URLSearchParams(params)}`,
      `${reportName}-report-${moment().format('DD-MM-YYYY_HH-mm')}`,
      'GET',
      { timeout: 90000 }
    )
  }

  previewNotification = (data) => {
    return this._post(`notifications/api/form/notification/preview`, data)
  }

  cancelContractPreview = (id) => {
    return this._get(
      `/contracts/api/contract-cancel?${new URLSearchParams({ id })}`
    )
  }

  cancelContract = (id, reason) => {
    return this._post(`/contracts/api/contract-cancel`, { id, reason })
  }

  reorderEmbeddedRecords = (app, model, records) => {
    return this._post(`/${app}/api/order/${model}`, { records })
  }

  duplicateWorkout = (id) => {
    return this._post(`/activities/api/duplicate-workout/${id}`)
  }

  /**
   *
   * @param {Number} printerId ID of the printer to use
   * @param {Array<String>} documents List of documents to include in the receipt
   * @returns {Promise<AxiosResponse>}
   */
  printReceipt = (printerId, documents) => {
    return this._post(`/finances/api/print/financial-document-receipt`, {
      printer_id: printerId,
      documents,
    })
  }

  /**
   *
   * @param {string} djangoApp Django app name
   * @param {string} djangoModel Django model name
   * @param {number} objectId Django object id (defaults to null and open a obj creation if not provided)
   * @returns
   */
  getAdminUrl = (djangoApp, djangoModel, objectId = null) => {
    const sanitizeDjangoStr = (val) => val.replace(/-/g, '').toLowerCase()
    let url = [
      process.env.REACT_APP_BACKEND_URL,
      'admin',
      sanitizeDjangoStr(djangoApp),
      sanitizeDjangoStr(djangoModel),
    ].join('/')
    if (objectId) {
      url += `/${objectId}/change/`
    } else {
      url += `/add/`
    }
    return url
  }

  getBoletoPrintURL = (documentNumber, return_format = null) => {
    const url = new URL(
      `${process.env.REACT_APP_BACKEND_URL}/finances/api/print/financial-document`
    )
    url.searchParams.set('number', documentNumber)
    if (return_format) url.searchParams.set('return_format', return_format)
    return url.toString()
  }
}

const HttpHandler = BaseHttpHandler.shared
export default HttpHandler
