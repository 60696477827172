import { Icon } from '@material-ui/core'
import { USER_TYPE } from 'app/ducks/auth/types'
import { modalFormOperations } from 'app/ducks/modal'
import { Store } from 'app/redux/Store'
import React from 'react'
import School from './school/School'
import { Theme } from 'app/components/MatxTheme/themes'

const ComingSoon = React.lazy(() => import('./users/comingSoon'))

export const signUp = {
  path: '/session/signup',
  component: React.lazy(() =>
    import('app/views/sessions/register/JwtRegister')
  ),
  isPublic: true,
}

export const signIn = {
  path: '/session/signin',
  component: React.lazy(() => import('app/views/sessions/login/JwtLogin')),
  isPublic: true,
}

export const biometryEnroll = {
  name: 'Cadastro de biometria',
  icon: 'fingerprint',
  path: '/biometry-enroll',
  allowedOnThemes: [Theme.AMSM],
  component: React.lazy(() =>
    import('app/views/biometry-enroll/BiometryEnroll')
  ),
  roles: [USER_TYPE.BIOMETRY_MANAGER],
}

export const physicalSpaceReservations = {
  name: 'Reservas de espaço',
  icon: 'deck',
  path: '/physical-space-reservations',
  allowedOnThemes: [Theme.AMSM, Theme.CIC],
  component: React.lazy(() =>
    import('app/views/physical-space-reservations/PhysicalSpaceReservations')
  ),
  roles: [
    USER_TYPE.BIOMETRY_MANAGER,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
}

export const physicalAccess = {
  name: 'Acesso Físico',
  icon: 'arrow_circle_up',
  path: '/physical-access',
  allowedOnThemes: [Theme.AMSM],
  component: React.lazy(() =>
    import('app/views/physical-access/PhysicalAccess')
  ),
  roles: [USER_TYPE.BIOMETRY_MANAGER, USER_TYPE.ADMINISTRATOR],
}

export const accountActivation = {
  path: '/session/account-activation',
  component: React.lazy(() =>
    import('app/views/sessions/activation/AccountActivation')
  ),
  isPublic: true,
}

export const forgotPassword = {
  path: '/session/forgot-password',
  component: React.lazy(() => import('app/views/sessions/ForgotPassword')),
  isPublic: true,
}

export const notFound = {
  path: '/session/404',
  component: React.lazy(() => import('app/views/sessions/NotFound')),
  isPublic: true,
}

export const servicesUnavailable = {
  path: '/services-unavailable',
  component: React.lazy(() => import('app/views/sessions/ServiceUnavailable')),
  isPublic: true,
}

export const dashboard = {
  name: 'Dashboard',
  icon: 'dashboard',
  path: '/dashboard',
  allowedOnThemes: [Theme.AMSM, Theme.ACTION, Theme.CIC],
  component: React.lazy(() => import('app/views/dashboard/Dashboard')),
  roles: [
    USER_TYPE.MEMBER,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
}

export const userDetails = {
  path: '/user/details/:id',
  exact: true,
  component: React.lazy(() =>
    import('app/views/users/UserDetails/UserDetails')
  ),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.MANAGER, USER_TYPE.COLLABORATOR],
}

export const userList = {
  name: 'Listagem de usuários',
  icon: 'people',
  iconText: 'SI',
  path: '/user/list',
  exact: true,
  component: React.lazy(() => import('app/views/users/UserList')),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.MANAGER, USER_TYPE.COLLABORATOR],
}

export const userForm = {
  path: '/user/form',
  exact: true,
  component: React.lazy(() => import('app/views/users/UserForm')),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.MANAGER, USER_TYPE.COLLABORATOR],
}

export const finances = {
  name: 'Finanças',
  icon: 'attach_money',
  path: '/finances',
  exact: true,
  component: React.lazy(() => import('app/views/finances/FinancesDashboard')),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.FINANCIAL],
  allowedOnThemes: [Theme.AMSM, Theme.CIC, Theme.BIOACADEMIA],
}

export const billingPage = {
  name: 'Cobrança Manual',
  icon: 'attach_money',
  path: '/billing',
  exact: true,
  component: React.lazy(() =>
    import('app/views/finances/BillingPage/BillingPage')
  ),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.FINANCIAL],
  allowedOnThemes: [Theme.AMSM, Theme.CIC, Theme.BIOACADEMIA],
}

export const financialDocumentsList = {
  name: 'Documentos Financeiros',
  icon: 'attach_money',
  path: '/financial-documents',
  exact: true,
  component: React.lazy(() =>
    import('app/views/finances/FinancialDocumentsList')
  ),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.FINANCIAL],
  allowedOnThemes: [Theme.AMSM, Theme.BIOACADEMIA],
}

export const calendar = {
  name: 'Calendário',
  icon: 'perm_contact_calendar',
  path: '/calendar',
  exact: true,
  component: React.lazy(() => import('app/views/calendar/Calendar')),
  roles: [
    USER_TYPE.ADMINISTRATOR,
    USER_TYPE.MANAGER,
    USER_TYPE.FINANCIAL,
    USER_TYPE.BIOMETRY_MANAGER,
  ],
  allowedOnThemes: [Theme.AMSM],
}

export const associationTitles = {
  name: 'Associados',
  icon: 'person',
  path: '/associados',
  exact: true,
  component: React.lazy(() =>
    import('app/views/association-title/AssociationTitleList')
  ),
  roles: [USER_TYPE.ADMINISTRATOR],
  allowedOnThemes: [Theme.AMSM],
}

export const financialMovementsForecasting = {
  name: 'Cobrança Manual',
  icon: 'attach_money',
  path: '/financial-movements-forecasting',
  exact: true,
  component: React.lazy(() =>
    import('app/views/finances/FinancialMovementsForecasting')
  ),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.FINANCIAL],
  allowedOnThemes: [Theme.AMSM, Theme.BIOACADEMIA],
}

export const financialDocumentsForecasting = {
  name: 'Cobrança Manual',
  icon: 'attach_money',
  path: '/financial-documents-forecasting',
  exact: true,
  component: React.lazy(() =>
    import('app/views/finances/FinancialDocumentsForecasting')
  ),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.FINANCIAL],
  allowedOnThemes: [Theme.AMSM, Theme.BIOACADEMIA],
}

export const paymentMethodsSync = {
  name: 'Cobrança Manual',
  icon: 'attach_money',
  path: '/payment-methods-sync',
  exact: true,
  component: React.lazy(() => import('app/views/finances/PaymentMethodsSync')),
  roles: [USER_TYPE.ADMINISTRATOR, USER_TYPE.FINANCIAL],
  allowedOnThemes: [Theme.AMSM],
}

export const benefits = {
  name: 'Programa de benefícios',
  icon: 'loyalty',
  path: '/benefits',
  exact: true,
  allowedOnThemes: [Theme.AMSM],
  component: () => <ComingSoon image="/assets/images/benefits.svg" />,
  badge: {
    color: 'error',
    value: 'Em breve!',
  },
  roles: [
    USER_TYPE.MEMBER,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
}

export const gym = {
  name: 'Academia',
  icon: 'sports_handball',
  path: '/gym',
  exact: true,
  component: React.lazy(() => import('app/views/gym/GymDashboard')),
  roles: [
    USER_TYPE.MEMBER,
    USER_TYPE.TOTEM,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.BIOMETRY_MANAGER, // TODO: Filter based on access type instead of role
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
  allowedOnThemes: [
    Theme.AMSM,
    Theme.ACTION,
    Theme.IGORSANDI,
    Theme.GUILHERMELAZARETTI,
    Theme.LARISSACIOATO,
    Theme.DCFIT,
  ],
}

export const contracts = {
  name: 'Modelos de Contrato',
  icon: 'article',
  path: '/contracts',
  exact: true,
  component: React.lazy(() => import('app/views/contracts/ContractsDashboard')),
  roles: [USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
  allowedOnThemes: [Theme.AMSM],
}

export const campaigns = {
  name: 'Campanhas',
  icon: 'campaigns',
  path: '/campaigns',
  exact: true,
  component: React.lazy(() => import('app/views/campaigns/Campaigns')),
  roles: [USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
  allowedOnThemes: [Theme.AMSM, Theme.CIC],
}

export const findPhysicalSpaces = {
  name: 'Espaços Físicos',
  icon: 'deck',
  path: '/find-physical-spaces',
  exact: true,
  component: React.lazy(() =>
    import('app/views/physical-spaces/FindPhysicalSpaces')
  ),
  allowedOnThemes: [Theme.AMSM, Theme.CIC],
  // TODO: Keep minimum age condition for amsm
  // minimumAge: 18,
  userCondition: (userInfo, featureFlags) =>
    featureFlags?.ENABLE_SPACE_RESERVATION ||
    userInfo?.is_physical_space_reservation_staff ||
    [USER_TYPE.BIOMETRY_MANAGER, USER_TYPE.ADMINISTRATOR].includes(
      userInfo?.user_type
    ),
}

export const physicalSpaceDetails = {
  name: 'Espaços Físico',
  icon: 'deck',
  path: '/physical-space/:id',
  exact: true,
  component: React.lazy(() =>
    import('app/views/physical-spaces/PhysicalSpaceDetails')
  ),
  roles: [
    USER_TYPE.MEMBER,
    USER_TYPE.TOTEM,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.BIOMETRY_MANAGER,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
}

export const physicalEvaluationForm = {
  name: 'Academia',
  icon: 'sports_handball',
  path: '/physical-evaluation-form/:id?',
  exact: true,
  component: React.lazy(() => import('app/views/gym/PhysicalEvaluationForm')),
  roles: [USER_TYPE.COLLABORATOR, USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
}

export const workouts = {
  name: 'Treinos',
  icon: 'sports_handball',
  path: '/workouts',
  exact: true,
  component: React.lazy(() => import('app/views/gym/Workouts')),
  roles: [
    USER_TYPE.TOTEM,
    USER_TYPE.MEMBER,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
}
export const findClasses = {
  name: 'Encontrar aulas',
  icon: 'search',
  path: '/find-classes',
  exact: true,
  component: React.lazy(() => import('app/views/gym/FindClasses')),
  roles: [
    USER_TYPE.TOTEM,
    USER_TYPE.MEMBER,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
}
export const activityClassesDetails = {
  name: 'Detalhes da aula',
  icon: 'school',
  path: '/activity-class-details/:id',
  exact: true,
  component: React.lazy(() => import('app/views/gym/ActivityClassDetails')),
  roles: [
    USER_TYPE.TOTEM,
    USER_TYPE.MEMBER,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],
}

export const workoutsDetails = {
  name: 'Treino',
  icon: 'sports_handball',
  path: '/workout/:id',
  exact: true,
  component: React.lazy(() => import('app/views/gym/WorkoutDetails')),
  roles: [
    USER_TYPE.TOTEM,
    USER_TYPE.MEMBER,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.COLLABORATOR,
    USER_TYPE.MANAGER,
    USER_TYPE.ADMINISTRATOR,
  ],

  // bottomBar: {
  //   centerButton: {
  //     icon: <Icon>printer</Icon>,
  //     label: 'Imprimir',
  //   },
  // },
}

export const workoutForm = {
  name: 'Formulário de Treino',
  icon: 'sports_handball',
  path: '/workout-form/:id?',
  exact: true,
  component: React.lazy(() => import('app/views/gym/WorkoutForm')),
  roles: [USER_TYPE.COLLABORATOR, USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
}
export const activityclassform = {
  name: 'Formulário de Aula',
  icon: 'sports_handball',
  path: '/activity-class/:id?',
  exact: true,
  component: React.lazy(() => import('app/views/gym/ActivityClassForm')),
  roles: [USER_TYPE.COLLABORATOR, USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
}

export const evaluations = {
  name: 'Avaliações',
  icon: 'sports_handball',
  path: '/evaluations',
  exact: true,
  component: React.lazy(() => import('app/views/gym/Evaluations')),
  roles: [USER_TYPE.COLLABORATOR, USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
}

export const schools = {
  name: 'Escolinhas',
  icon: 'sports_soccer',
  path: '/schools',
  exact: true,
  allowedOnThemes: [Theme.AMSM],
  component: () => <School />,
  roles: [USER_TYPE.COLLABORATOR, USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
}

export const spaceRenting = {
  name: 'Aluguel de espaços',
  icon: 'house_siding',
  path: '/space-renting',
  exact: true,
  allowedOnThemes: [Theme.AMSM],
  component: React.lazy(() =>
    import('app/views/physical-space-renting/PhysicalSpaceRenting')
  ),
  userCondition: (userInfo, featureFlags) =>
    featureFlags?.ENABLE_SPACE_RESERVATION ||
    userInfo?.is_physical_space_reservation_staff ||
    [USER_TYPE.BIOMETRY_MANAGER, USER_TYPE.ADMINISTRATOR].includes(
      userInfo?.user_type
    ),
}

export const tickets = {
  name: 'Cobranças',
  icon: 'local_atm',
  path: '/user-financial-documents',
  exact: true,
  allowedOnThemes: [Theme.AMSM, Theme.CIC],
  component: React.lazy(() =>
    import('./user-financial-documents/UserFinancialDocuments')
  ),
  // TODO: Remove once implemented
  badge: {
    color: 'error',
    value: 'Em breve!',
  },
  roles: [
    USER_TYPE.MEMBER,
    USER_TYPE.RELATIVE,
    USER_TYPE.RESPONSIBLE,
    USER_TYPE.ASSOCIATE,
    USER_TYPE.ADMINISTRATOR,
  ],
}

export const customReport = {
  name: 'Relatórios',
  icon: 'content_paste_search_icon',
  path: '/custom-report',
  exact: true,
  allowedOnThemes: [Theme.AMSM, Theme.CIC],
  component: React.lazy(() => import('./custom-report/CustomReport')),
  roles: [USER_TYPE.COLLABORATOR, USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
}

export const userPersonalData = {
  path: '/personal-data',
  exact: true,
  component: React.lazy(() => import('./users/UserPersonalData')),
  badge: {
    color: 'error',
    value: 'Em breve!',
  },
  bottomBar: {
    rightButton: {
      label: 'Editar',
      icon: <Icon>create</Icon>,
      onClick: () => {
        const state = Store.getState()
        Store.dispatch(
          modalFormOperations.showModalForm({
            app: 'users',
            model: 'user',
            id: state.auth?.userInfo?.id,
          })
        )
      },
    },
  },
}

export const physicalDeviceManagement = {
  name: 'Controle de acesso',
  icon: 'login',
  path: '/physical-device-management',
  exact: true,
  component: React.lazy(() =>
    import('./physical-device-management/PhysicalDeviceManagement')
  ),
  allowedOnThemes: [Theme.AMSM],
  roles: [USER_TYPE.ADMINISTRATOR],
}

export const survey = {
  name: 'Questionário',
  icon: 'file',
  path: '/survey',
  exact: true,
  component: React.lazy(() => import('./survey/SurveyForm')),
  roles: [USER_TYPE.COLLABORATOR, USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR],
}

export const totem = {
  name: 'TotemDisplay',
  path: '/totem-display',
  exact: true,
  component: React.lazy(() => import('./totem/TotemDisplay')),
  roles: [USER_TYPE.TOTEM, USER_TYPE.ADMINISTRATOR],
  hideLayout: true,
}

export const healthInsuranceComparisonReport = {
  name: 'Planos de Saúde',
  icon: 'health_and_safety',
  path: '/health-insurance-comparison-report',
  exact: true,
  component: React.lazy(() =>
    import('app/views/health-insurance/HealthInsuranceComparisonReport')
  ),
  roles: [USER_TYPE.ADMINISTRATOR],
  allowedOnThemes: [Theme.AMSM],
}
